import React from "react"
import Layout from "../components/layout"

const Gavuhandan = () => {
    return (
        <Layout>
            <h1>Gávuhandan</h1>
        </Layout>
    )
}

export default Gavuhandan